export const identityQuestionBank = {
  title: "Identity",
  elements: [
    {
      type: "radiogroup",
      name: "identity-id",
      title: "Would you provide a government ID to a third party?",
      choices: ["Yes", "No"],
      correctAnswer: "Yes",
      html: "",
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "identity-bank",
      title: "Would you be willing to connect a bank account?",
      choices: ["Yes", "No"],
      correctAnswer: "Yes",
      html: "",
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "identity-media",
      title: "Would you be willing to connect your social media accounts?",
      choices: ["Yes", "No"],
      correctAnswer: "Yes",
      html: "",
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "identity-basic",
      title: "Would you prefer to only give basic information?",
      choices: ["Yes", "No"],
      correctAnswer: "Yes",
      html: "",
      isRequired: true,
    },
  ],
};
