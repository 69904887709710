import React, { useCallback, useEffect, useRef, useState } from "react";
import mergeImages from 'merge-images'
import SVG from "react-inlinesvg";
import "./avatar.css";
import ReactCanvasConfetti from "react-canvas-confetti";
import { RadarChartOption2 } from "../Chart/ChartOptionTwo";

import base from './assets/base.png'

// Eyes
import eyes01 from './assets/eyes/eyes01.png'
import eyes02 from './assets/eyes/eyes02.png'
import eyes03 from './assets/eyes/eyes03.png'
import eyes04 from './assets/eyes/eyes04.png'
import eyes05 from './assets/eyes/eyes05.png'

// Mouth
import mouth01 from './assets/mouth/mouth01.png'
import mouth02 from './assets/mouth/mouth02.png'
import mouth03 from './assets/mouth/mouth03.png'
import mouth04 from './assets/mouth/mouth04.png'
import mouth05 from './assets/mouth/mouth05.png'

// Hats
import hat00 from './assets/hats/hat00.png'
import hat01 from './assets/hats/hat01.png'
import hat02 from './assets/hats/hat02.png'
import hat03 from './assets/hats/hat03.png'
import hat04 from './assets/hats/hat04.png'
import hat05 from './assets/hats/hat05.png'
import hat06 from './assets/hats/hat06.png'
import hat07 from './assets/hats/hat07.png'
import hat08 from './assets/hats/hat08.png'
import hat09 from './assets/hats/hat09.png'

const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
} as React.CSSProperties;

export function AvatarOptionTwo(quizResults) {
    const refAnimationInstance = useRef(null);
    const [avatarImg, setAvatarImg] = useState('');
    const qr = quizResults.quizResults;

    const getInstance = useCallback((instance) => {
      refAnimationInstance.current = instance;
    }, []);
  
    const makeShot = useCallback((particleRatio, opts) => {
      refAnimationInstance.current &&
        refAnimationInstance.current({
          ...opts,
          origin: { y: 0.5 },
          particleCount: Math.floor(200 * particleRatio),
        });
    }, []);
  
    const fire = useCallback(() => {
      makeShot(0.25, {
        spread: 26,
        startVelocity: 55,
      });
  
      makeShot(0.2, {
        spread: 60,
      });
  
      makeShot(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
  
      makeShot(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
  
      makeShot(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    }, [makeShot]);
  
    useEffect(
      fire, // <- function that will run on every dependency update
      []
    );

    const eyeMap: Record<number, string> = {
        1: eyes01, 2: eyes02, 3: eyes03, 4: eyes04, 5: eyes05
    }
    const mouthMap: Record<number, string> = {
        1: mouth01, 2: mouth02, 3: mouth03, 4: mouth04, 5: mouth05
    }
    const hatMap: Record<number, string> = {
        0: hat00, 1: hat01, 2: hat02, 3: hat03, 4: hat04,
        5: hat05, 6: hat06, 7: hat07, 8: hat08, 9: hat09,
    }


    mergeImages([
        base, 
        eyeMap[qr.identity], 
        mouthMap[qr.knowledge], 
        hatMap[qr.reputation]
    ]).then((b64String) => setAvatarImg(b64String));


    return (
        <div style={{ zIndex: 2 }}>
          <h2>Congrats! Your New Avatar:</h2>
          <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
          {/* <SVG src={avatarImg} /> */}
          <img src={avatarImg} width={400} alt="Your awesome avatar here"/>
          <RadarChartOption2
            quizResults={quizResults}
            avatarImage={avatarImg}
          ></RadarChartOption2>
        </div>
      );
}