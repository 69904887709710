import React, { useCallback, useEffect, useRef } from "react";
import "./chart.css";
import mergeImages from "merge-images";
import resizebase64 from "resize-base64";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis } from "recharts";

function downloadAvatarBlob(blob, filename) {
  const objectUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

function downloadAvatarChart(chartRef, avatar) {
  // Create Avatar Image Object
  const blob = new Blob([avatar], {
    type: "image/svg+xml",
  });
  const objectUrl = URL.createObjectURL(blob);

  const img = new Image(428, 428);
  img.src = objectUrl;

  // Create Chart Image Object
  let chartImage = new Image();
  let svg = chartRef.current.container.children[0];

  // Add background to the graph on download/export
  //svg.setAttribute("style", "background-color: white");

  let xml = new XMLSerializer().serializeToString(svg);
  console.log(xml);
  let dataUrl = "data:image/svg+xml;utf8," + encodeURIComponent(xml);
  chartImage.src = dataUrl;

  mergeImages(
    [
      { src: img.src, x: 0, y: 10 },
      { src: chartImage.src, x: 125, y: 652 },
    ],
    {
      width: 652,
      height: 1000,
    }
  ).then((b64) => {
    const a = document.createElement("a");
    a.download = "avatarGraph.jpg";
    a.href = b64;
    a.click();
  });
}

export function RadarChartOption2(quizAvatarData) {
  const chartRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  const downloadSVG = useCallback(() => {
    const blob = new Blob([quizAvatarData.avatarImage], {
      type: "image/svg+xml",
    });
    downloadAvatarBlob(blob, `myAvatar.svg`);
  }, []);

  const downloadChart = useCallback(() => {
    downloadAvatarChart(chartRef, quizAvatarData.avatarImage);
  }, []);

  const downloadPNGChart = useCallback(() => {
  // Create Avatar Image Object
  const img = resizebase64(quizAvatarData.avatarImage, 652, 652);

  // Create Chart Image Object
  let chartImage = new Image();
  let svg = chartRef.current.container.children[0];
  let xml = new XMLSerializer().serializeToString(svg);
  console.log(xml);
  let dataUrl = "data:image/svg+xml;utf8," + encodeURIComponent(xml);
  chartImage.src = dataUrl;

  mergeImages(
    [
      { src: img, x: 0, y: 0 },
      { src: chartImage.src, x: 125, y: 652 },
    ],
    {
      width: 652,
      height: 1000,
    }
  ).then((b64) => {
    const a = document.createElement("a");
    a.download = "avatarGraph.png";
    a.href = b64;
    a.click();
  });
  }, []);

  const downloadPNG = useCallback(() => {
    const link = document.createElement("a");
    link.href = quizAvatarData.avatarImage;
    link.download = "myavatar.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const quizData: any = [
    {
      category: "Knowledge",
      score: quizAvatarData.quizResults.quizResults.knowledge,
    },
    {
      category: "Reputation",
      score: quizAvatarData.quizResults.quizResults.reputation,
    },
    {
      category: "Identity",
      score: quizAvatarData.quizResults.quizResults.identity,
    },
  ];

  return (
    <div>
      <RadarChart
        ref={chartRef}
        cx={200}
        cy={200}
        outerRadius={120}
        width={400}
        height={300}
        data={quizData}
        style={{
          backgroundColor: "white",
          opacity: 0.9,
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 20,
        }}
      >
        <text
          x={200}
          y={20}
          fill="black"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan fontSize="16">Avatar Questionnaire Result</tspan>
        </text>
        <PolarGrid />
        <PolarAngleAxis dataKey="category" fontSize={16} />
        {/*<PolarRadiusAxis*/}
        {/*  angle={45}*/}
        {/*  fontSize={16}*/}
        {/*  domain={[0, Math.max(...quizAvatarData.quizResults.quizResults)]}*/}
        {/*/>*/}
        <Radar
          name="radar"
          dataKey="score"
          stroke="#FFB92A"
          fill="#FFB92A"
          fillOpacity={0.6}
        />
      </RadarChart>
      <span>
        <button className="button" onClick={downloadPNG}>
          Download Avatar (.png)
        </button>
        <button className="button" onClick={downloadPNGChart}>
          Download Both (.png)
        </button>
      </span>
    </div>
  );
}
