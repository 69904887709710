export const data = {
  title: "NFT Questionnaire",
  showProgressBar: "bottom",
  firstPageIsStarted: true,
  startSurveyText: "Start Questionnaire",
  pages: [
    {
      elements: [
        {
          type: "html",
          html: "You are about to start a questionnaire on NFT's. <br/>Please click on <b>'Start Questionnaire'</b> button when you are ready.",
        },
      ],
    },
  ],
};
