export const reputationQuestionBank = {
  title: "Reputation",
  elements: [
    {
      type: "radiogroup",
      name: "reputation-community",
      title: "How many NFT communities are you active in?",
      choices: [
        { text: "None", value: 0 },
        { text: "Some (1-2)", value: 1 },
        { text: "Few (3-5)", value: 2 },
        { text: "Many (5+)", value: 3 },
      ],
      correctAnswer: "Many (5+)",
      html: "",
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "reputation-time",
      title: "How long have you been into NFTs?",
      choices: [
        { text: "Just heard about them", value: 0 },
        { text: "Recently heard about them", value: 1 },
        { text: "Have been in the space for a some time", value: 2 },
        { text: "Have been in the space for a long time", value: 3 },
      ],
      correctAnswer: "Have been in the space for a long time",
      html: "",
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "reputation-event",
      title: "How many NFT events have you attended?",
      choices: [
        { text: "None", value: 0 },
        { text: "Some (1-2)", value: 1 },
        { text: "Few (3-5)", value: 2 },
        { text: "Many (5+)", value: 3 },
      ],
      correctAnswer: "Many (5+)",
      html: "",
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "reputation-txns",
      title: "How many NFT/crypto transactions have you made?",
      choices: [
        { text: "None", value: 0 },
        { text: "Some (1-2)", value: 1 },
        { text: "Few (3-5)", value: 2 },
        { text: "Many (5+)", value: 3 },
      ],
      correctAnswer: "Many (5+)",
      html: "",
      isRequired: true,
    },
  ],
};
