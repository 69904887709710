export const knowledgeQuestionBank = {
  title: "Knowledge",
  elements: [
    {
      type: "radiogroup",
      name: "knowledge-technology",
      title: "What technology automates the transactions of NFTs?",
      choicesOrder: "random",
      choices: ["HTML", "Clever Contracts", "Smart Contracts", "Javascript"],
      correctAnswer: "Smart Contracts",
      html: "",
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "knowledge-process",
      title:
        "What is the process called when you convert digital files into a digital asset?",
      choicesOrder: "random",
      choices: ["Foraging", "Minting", "Digitizing", "Caramelizing"],
      correctAnswer: "Minting",
      html: "",
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "knowledge-ledger",
      title:
        "What technology acts as a public ledger to record peer-to-peer transactions?",
      choicesOrder: "random",
      choices: ["Blockchain", "LedgerTech", "Book-keep", "Sphererope"],
      correctAnswer: "Blockchain",
      html: "",
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "knowledge-dao",
      title: "What does a DAO NFT allow holders to do?",
      choicesOrder: "random",
      choices: [
        "Allows access to NFT marketplaces",
        "Brings the holder good luck",
        "Allows a holder to resell an NFT",
        "Gives voting rights in an organization",
      ],
      correctAnswer: "Gives voting rights in an organization",
      html: "",
      isRequired: true,
    },
  ],
};
