import React, { useState } from "react";
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import "./quiz.css";
import { data } from "./questions";
import { Avatar } from "../Avatar/Avatar";
import { RadarChart } from "../Chart/Chart";
import { knowledgeQuestionBank } from "./question-bank/knowledge-question-bank";
import { reputationQuestionBank } from "./question-bank/reputation-question-bank";
import { identityQuestionBank } from "./question-bank/identity-question-bank";
import { AvatarOptionTwo } from "../Avatar/AvatarOptionTwo";

StylesManager.applyTheme("defaultV2");

// Return array with 3 questions selected from a bank
function randomBankOf3Questions(array: any) {
  return array.sort(() => 0.5 - Math.random()).slice(0, 3);
}

export function Quiz() {
  const [quizComplete, setQuizComplete] = useState(false);
  const [quizResults, setQuizResults] = useState({});

  let knowledgeCorrectAmount = 0;
  let reputationCorrectAmount = 0;
  let identityCorrectAmount = 0;

  // Setup random bank of questions from each category
  knowledgeQuestionBank.elements = randomBankOf3Questions(
    knowledgeQuestionBank.elements
  );
  reputationQuestionBank.elements = randomBankOf3Questions(
    reputationQuestionBank.elements
  );
  identityQuestionBank.elements = randomBankOf3Questions(
    identityQuestionBank.elements
  );
  // Add random questions to quiz
  data.pages.push(knowledgeQuestionBank);
  data.pages.push(reputationQuestionBank);
  data.pages.push(identityQuestionBank);

  function onComplete(survey) {
    survey.getQuizQuestions().forEach((question) => {
      if (question.name.includes("knowledge") && question.isAnswerCorrect()) {
        knowledgeCorrectAmount++;
      }
      if (question.name.includes("reputation")) {
        reputationCorrectAmount += question.value;
      }
      if (question.name.includes("identity") && question.isAnswerCorrect()) {
        identityCorrectAmount++;
      }
    });
    setQuizResults({
      knowledge: knowledgeCorrectAmount,
      reputation: reputationCorrectAmount,
      identity: identityCorrectAmount,
    });
    setQuizComplete(true);
  }

  function onValueChanged(_: any, options: any) {
    //console.log("New value: ", options.question.isAnswerCorrect());
  }

  const model = new Model(data);

  return (
    <div className="surveyElement" style={{ zIndex: 2 }}>
      {!quizComplete ? (
        <Survey
          model={model}
          onComplete={onComplete}
          onValueChanged={onValueChanged}
          showCompletedPage={false}
        />
      ) : (
        <div>
          {/* <Avatar quizResults={quizResults}></Avatar> */}
          <AvatarOptionTwo quizResults={quizResults} />
        </div>
      )}
    </div>
  );
}
